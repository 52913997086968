import Filter from './Filter'
import Loading from '../Loading'
import React from 'react'
import useResultStore from '../../store/resultStore'
import Heatmap from './heatmap/Heatmap'
import NotFoundPeptides from './NotFoundPeptides'
import CsvDownloadButton from './CsvDownloadButton'
import usePeptidesCsvExport from '../../hooks/usePeptidesCsvExport'

const OffTargetResult = () => {
  const { result, loading, hlaFilter } = useResultStore()
  const data = usePeptidesCsvExport()
  const randomPostfix = crypto.randomUUID().substring(0, 8)
  // OffTargetTox_results_<filter>_<random_differentiator>
  const filename = `OffTargetTox_results_${hlaFilter.replace(/[^a-zA-Z0-9]/g, '')}_${randomPostfix}.csv`

  if (!loading && (!result)) return null

  return (
    <div className='mt-6 space-y-6 border-t border-slate-100 pt-6'>
      <div className='space-y-6 bg-white p-6 shadow-sm'>
        <div className='mb-4 flex h-16 w-full items-center'>
          <h3 className='mb-0 mr-auto'>Results</h3>
          {(result ?? []).length > 0 && 
          <div className='ml-auto flex items-center space-x-4'>
            <Filter />
            <CsvDownloadButton data={data} filename={filename} />
          </div>
          }
        </div>
        {loading && <Loading label='Loading results...' />}
        <Heatmap />
        <NotFoundPeptides />
      </div>
    </div>
  )
}

export default OffTargetResult