import React, { useRef } from 'react'
import useResultStore from '../../store/resultStore'
import { SortBy, SortingDirection } from '../../types/SortingDirection'

const sortingDirections: Record<SortingDirection, string> = {
  [SortingDirection.Ascending]: 'Ascending',
  [SortingDirection.Descending]: 'Descending',
  [SortingDirection.AsInput]: 'As Input'
}

const sortBys: { [ key in SortBy ]?: string } = {
  [SortBy.CategorySize]: 'Category Size',
  [SortBy.Intensity]: 'Intensity',
  [SortBy.Peptide]: 'Peptide'
}

const Filter = () => {
  const { result, sortingDirection, setSortingDirection, sortBy, setSortBy } = useResultStore()
  const sortingDirectionRef = useRef<HTMLSelectElement>()
  const sortByRef = useRef<HTMLSelectElement>()

  if (!result) return null

  const onChangeSortingDirection = () => setSortingDirection(sortingDirectionRef.current.value as SortingDirection)

  const onChangeSortBy = () => setSortBy(sortByRef.current.value as SortBy)

  return (
    <div className='flex items-center space-x-4'>
      <label className='mb-0'>Sort by:</label>
      <select onChange={onChangeSortBy} ref={sortByRef} value={sortBy}>
        {Object.entries(sortBys).map(([ property, label ]) => <option value={property} key={property}>{label}</option>)}
      </select>
      <select onChange={onChangeSortingDirection} ref={sortingDirectionRef} value={sortingDirection}>
        {Object.entries(sortingDirections).map(([ direction, label ]) => <option value={direction} key={direction}>{label}</option>)}
      </select>
    </div>
  )
}

export default Filter