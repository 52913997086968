import { useMemo } from 'react'
import useResultStore from '../store/resultStore'
import PeptidesApiResponse from '../types/responses/PeptidesApiResponse'

export const calcNotFoundPeptides = (result, userInput): string[] => {
  if (!result) return []

  const peptides = (result as PeptidesApiResponse).reduce((acc, peptide) => {
    acc.push(peptide.peptide)

    return acc
  }, [])

  return userInput.filter(peptide => !peptides.includes(peptide))
}

const useNotFoundPeptides = (): string[] => {
  const { result, userInput } = useResultStore()

  return useMemo((): string[] => {
    return calcNotFoundPeptides(result, userInput)
  }, [ userInput, result ])
}

export default useNotFoundPeptides