import useNotFoundPeptides from '../../hooks/useNotFoundPeptides'
import NoResultMessageBox from '../NoResultMessageBox'

const NotFoundPeptides = () => {
  const notFoundPeptides = useNotFoundPeptides()
  const count = notFoundPeptides.length
  const hasNotFoundPeptides = !notFoundPeptides || count === 0

  if (hasNotFoundPeptides) return null

  const data = notFoundPeptides.join(', ')

  return (
    <NoResultMessageBox>
      <>
        <p>Unfortunately, we were unable to find the following peptides {count && <>({count})</>} in healthy samples. However, all peptides are included in the CSV file.</p>
        {data && <p className='pt-2 font-medium' data-testid='unmatched-peptide'>{data}</p>}
      </>
    </NoResultMessageBox>
  )
}

export default NotFoundPeptides